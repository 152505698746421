import { useEffect } from 'react';
import { useAuth, useOrganizationList } from '@clerk/nextjs';

export default function SyncActiveOrganisation() {
    const { setActive, isLoaded } = useOrganizationList();

    // Get the organization ID from the session
    const { orgId } = useAuth();

    useEffect(() => {
        if (!isLoaded) return;

        if (orgId !== 'org_2bPpCGE2XRcOynAKktw8htis0Xt') {
            console.log('setactive');
            setActive({ organization: 'org_2bPpCGE2XRcOynAKktw8htis0Xt' });
        }
    }, [orgId, isLoaded, setActive]);

    return null;
}
