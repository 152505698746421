import isEmpty from 'lodash/isEmpty';

export const hasAccessToImport = (role) => {
    const allowedRoles = ['admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const hasAccessToOcr = (role) => {
    const allowedRoles = ['ocr', 'admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const hasAccessToOldVaults = (role) => {
    const allowedRoles = ['admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const hasAccessToJson = (role) => {
    const allowedRoles = ['superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const hasAccessToReports = (role) => {
    const allowedRoles = ['reports', 'admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const hasAccessToCruks = (role) => {
    const allowedRoles = ['cruks', 'admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const hasAccessToCcbr = (role) => {
    const allowedRoles = ['ccbr', 'admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};
export const hasAccessToCra = (role) => {
    const allowedRoles = ['cra', 'admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};
export const hasAccessToDocuments = (role) => {
    const allowedRoles = ['documents', 'admin', 'superadmin'];
    return !isEmpty(role) && allowedRoles.includes(role);
};

export const checkUserPermission = (session, permissions) => {
    if (
        !session ||
        !session.user ||
        !session.user.organizationMemberships ||
        session.user.organizationMemberships.length === 0
    ) {
        return null; // Return null if the user is not a basic member
    }

    const organizationMemberships = session.user.organizationMemberships;
    const userPermissions = organizationMemberships[0]?.permissions || [];

    const hasPermssions = permissions.some((e) => userPermissions.includes(e));

    return hasPermssions; // Return null if no role is found in the memberships
};
