import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AdbIcon from '@mui/icons-material/Adb';
import { useRouter } from 'next/router';
import { SignedIn, SignedOut, SignInButton, UserButton, useSession } from '@clerk/nextjs';

import theme from 'theme/theme';

import { checkUserPermission } from 'lib/accessHelper';

function ResponsiveAppBar() {
    const router = useRouter();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [pages, setPages] = React.useState([]);

    const { session } = useSession();
    const hasVaultAccess = checkUserPermission(session, ['org:vault:access']);
    const hasCcbrAccess = checkUserPermission(session, ['org:ccbr:access']);
    const hasCruksAccess = checkUserPermission(session, ['org:cruks:access']);
    const hasCraAccess = checkUserPermission(session, ['org:cra:access']);
    const hasDocumentsAccess = checkUserPermission(session, ['org:documents:access']);
    const hasReportsAccess = checkUserPermission(session, ['org:reports:access']);

    React.useEffect(() => {
        const _pages = [];
        if (hasVaultAccess) {
            _pages.push({ name: 'Vault', link: '/vault', title: 'Vault' });
        }
        if (hasReportsAccess) {
            _pages.push({ name: 'Reports', link: '/subjective-reports', title: 'Subjective Reports' });
        }
        if (hasCcbrAccess) {
            _pages.push({ name: 'CCBR', link: '/ccbr', title: 'Guardianship Bulk Check' });
        }
        if (hasCruksAccess) {
            _pages.push({ name: 'CRUKS', link: '/cruks', title: 'CRUKS Registry Bulk Check' });
        }
        if (hasCraAccess) {
            _pages.push({ name: 'CRA', link: '/cra-reports', title: 'CRA Reports' });
        }
        if (hasDocumentsAccess) {
            _pages.push({ name: 'Documents', link: '/documents', title: 'Documents' });
        }

        setPages(_pages);
    }, [hasVaultAccess, hasReportsAccess, hasCcbrAccess, hasCruksAccess, hasCraAccess, hasDocumentsAccess]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (link) => {
        router.push(link);
        setAnchorElNav(null);
    };

    //console.log('pages', pages);

    return (
        <AppBar position="static" sx={{ backgroundColor: theme.palette.common.white }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ width: 50, borderRadius: 150 }} component="img" src="/logo.jpg" />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages &&
                                pages.map((page) => (
                                    <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page.link)}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages &&
                            pages.map((page) => (
                                <Button
                                    key={page.link}
                                    onClick={() => handleCloseNavMenu(page.link)}
                                    sx={{ my: 2, color: theme.palette.grey[500], display: 'block' }}
                                >
                                    {page.name}
                                </Button>
                            ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <SignedIn>
                            <UserButton showName />
                        </SignedIn>
                        <SignedOut>
                            <SignInButton />
                        </SignedOut>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
