const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const appEnvironment = publicRuntimeConfig.appEnvironment || 'staging';
const appVersion = publicRuntimeConfig.appVersion;
const blockedCountries = publicRuntimeConfig.blockedCountries;
const resizeImages = publicRuntimeConfig.resizeImages || 0;

console.log('Config - App environment = ', appEnvironment);

export const config = {
    staging: {
        logtailEnabled: true,
        logtailSourceToken: 'RLkR6zdhEofEEtVi7g46EGa8',
        parallelDocuments: false,
        klippaApiUrl: 'https://test.custom-ocr.klippa.com/api/v1/parseDocument',
        klippaApiKey: 'nPXjAvRL30H6DlqQdQRj3eaxM4tTpfkc',
        firebaseServiceAccount: {
            type: 'service_account',
            project_id: 'id-document-ocr-staging',
            private_key_id: '6700635b86bf4f05b05207b79011b0ba889af7e0',
            private_key:
                '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCL1cnpGN4Mju06\nALXSj5yy3Z6wK2SUqYJisaThSgsAtXGr7EsTNRjzzAgiDniOXym8BjvKha3Lqp+f\ndVkCPsL26j7kvQmIq+A+hyuvoZvIovDY4EeMZGF67SJ3xAOyhB/MlooPh1jFzrb+\noORWaKdhXO9O/KoT23j0qw6N9sCGCFMC3KUXtyRIqZPJg+msvNZDa+ToY/CigUVM\nEqajIMvRYgt29lCnTboHVUVkPL9QUQ9RVqilEYlRN2aqgvRFVCvEow5hfLU2+A9i\nYIJv625ZNM79cBcnOWDLasVxI6SokRUZUguP/eRH67lHAMG3vjS0/4s+LFHvvJqE\nSTL3xC55AgMBAAECggEACYH3wImYT3JIXvE9a5uoI+E9sOzCYflEjEFX3JmUNfAg\nUng2go/RqoHs66PgPo3p4Ibr+dEtB/QJ9mWcL7LkvrUeoZ7YzxognR/DsKVkWjf5\n+EUHmFwa5KjK9ym0tqaVIuv5ZyTkvE5Z0uZNoDJtbwlKnCxBxCfgCYK38p8caAB/\nKYq+QV4mRCH8scuFCtwGDCNZJGmruY/06d0m2vRTkQRLtrD1vql1zcmzsCez7AhA\nW5yEj+qM2eJ8iAbnFIiRv11vR+nDzHHeQlfCYYcsyXFJrAb0lgLZObjRYPrAzaCa\ndX1h7Ju1dHGztw1zVYS4cP701zzUh8nUWt8Zkh4rIQKBgQC+1qj2wgIRusJEYEIt\nvBQN6fdcTB/lUTc52I1uD/BKSIb2KUu7q0EiKn+uphSV5LQtm0Sq7WuVN4A6yqB1\nq8nxejOvfn60gkekGYK8NiYrlwrgS4MzUhQsK8mZeUtq3KgNcCOmcrFtHybond8e\nkaUOOWjXisrPUXpfGK4w2/g0DwKBgQC7lOHiCLNBTYtXd34CWk6l3qkVuQ8uhXkD\nEuJ6wIHw6WF5yQgWJ8HhcVCtncorC1DLbaHWZW4RQqiOFeu86Jy17aRNkvX56iMq\nPQfJXnMWmdREVffioMhowYXgfVoxCsucOQv1UOkSfzkWBIadAN36fevaaUnamNZy\nTYhea1LM9wKBgBje5uRJwnVHDpxUoytMsJxjmn4n2ZxMKlNamov2fc1giF46YYlS\nt0lZmoxLTrF+PdEXxCo2iOfIdOi0kZbkTYwv7d54MNEbjXSgcn5O3EigBaXXkYC1\nuLVM+5gJy79ghHJD5BGELwVWov573KEiQA2Lg9NbXjUInFcaY1EwybcXAoGBAJCq\nVraK/Sv80NzpA95upT8ZBJADQ+kd+ODc/Juswcn5Qzq4RqtHiCgEyzLKgTNHK81n\nxKtjWEqpfPLvhvoO72UDXHaTQ261Gt+lZ87uL5YblvAI2P8H/ueDcGtcYWV3LOZT\nvJw6BTZFtdH8NZD6LTfVS0MLdnDG9IP8tZIT9SyrAoGAFZBkVShocLxG0BzZFiQ6\nZzTLjDeSAZMV/I9LH6BcZlsSd+OIGuh0DWl4XyAyw6Ezj8qQx7n7nJU/lnAd3lYs\nmRFq9vn+88cBlb4Mme8rj9CGuBnmTDtopprolh+XTWZ0n5X9Y3UZGpx3oUEPwEy2\nVP4o19gKMKl69967UVZJa1c=\n-----END PRIVATE KEY-----\n',
            client_email: 'firebase-adminsdk-efkqx@id-document-ocr-staging.iam.gserviceaccount.com',
            client_id: '108491516130690783127',
            auth_uri: 'https://accounts.google.com/o/oauth2/auth',
            token_uri: 'https://oauth2.googleapis.com/token',
            auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
            client_x509_cert_url:
                'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-efkqx%40id-document-ocr-staging.iam.gserviceaccount.com',
        },
        firebaseProjectId: 'id-document-ocr-staging',
        firebaseAppId: '1:734294477864:web:ca6eed44656b9b7434391d',
        version: appVersion,
        elasticSearchKey: 'search-68y75gcekrbih5s6wf576yr5',
        elasticEngineName: 'id-ocr-search-staging',
        elasticEndpointBase: 'https://play-north-search.ent.europe-west4.gcp.elastic-cloud.com',
        idAnalyzerKey: 'nGmDtNmlbdEojkpBJJz1eC3qPuib4R5V',
        sentryDsn: 'https://8189f8d8b5274630ba975608228c17c8@o207019.ingest.sentry.io/6094294',
        sentryEnvironment: 'aml-staging',
        environment: 'staging',
        resizeImages,
        blockedCountries,
        pusherAppId: '1693401',
        pusherAppKey: '2e35e1937b9a317fb0e0',
        pusherAppSecret: 'ecbcf1a1ef59ac7e01ec',
    },
    production: {
        logtailEnabled: true,
        logtailSourceToken: 'RLkR6zdhEofEEtVi7g46EGa8',
        parallelDocuments: false,
        klippaApiUrl: 'https://custom-ocr.klippa.com/api/v1/parseDocument',
        klippaApiKey: 's1yCLqZzDAAYnc7s02w5PQV7Wi08OkJQ',
        firebaseServiceAccount: {
            type: 'service_account',
            project_id: 'id-document-ocr',
            private_key_id: 'c84d38fee68d43a9de86f71c3d23196778336220',
            private_key:
                '-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCiDAvAJsjVS6/K\nNTRzBFlYlHE9yWfDh47d3VreQVxcfAZreiaWG/Owfo3H53scJYBitC3SwDR/0VcE\n7jprhLO2Wzi32BghCqkGnL676dpXBkYJGiC/B9c9bYh2GYUTk38tETBCcCDgs9nG\nR8YUj/Lj4lMYgUSgAkeIyZc6JzmADTXNgL82esmo+SSgrk7NpSe8rB++oVsY4ABn\nDFKpLXCfoYgjADBv7jk7E4LqHW0pp4JSEUg0Iw+vyFZ+HyB+TtMU6h9jQGl68FLj\n8O0V576S/JVY0hRANzezk+HiIJFCyeGvmBOwVc6Br1ZfpWA4NlT/uNdWlnPkeR8i\n5snPzSKtAgMBAAECggEABZFhY/r71+xaVW8qPAYluxfv+NNZ8ot5WraM3+nMrnbV\nJ6M8/oJmXKWfNy5aegybJ95mBqxJAYKkDqIlirqMDD4qfuf4vZs7/ziqixK4Ha5f\nAK1k+6YIJ28DNdT0UB23dx50kzZ+wrRwoqdRBIraVH8weL0+/4/PJ8GO0IDhhmdK\nd03lkUrq3iEXtWh6rTy7WiDBzNNZ+LcpDQ9eWWdGrGB1nh/6n+MZjRPglwBWIIp3\nut1Xak22QJlfudtg1EIFfSgAAPrq+qvILOPWmhxPQZLSy8Zt22IJWOlqukL8sXyP\nKfv7BxDrvRpA+NxiPV0lkIYqx4T+6Kyv9e0HJDZGMQKBgQDLzRObKP+yE4qq30yG\n53nZZiW38+WpsszHAvJHjFlIlCIg5CsP3qFdj0MAmG25UYCV82lXTo7H8MLcYAh/\nIrP6wv6n5UTE/dclXZsv5DXk8ryjNDyJ/KY28CJ5BI6NuxKeE29EeMb+kXG5gOyh\nrGY/Am41xnbuKArCyPOdq0Xe9QKBgQDLjTjXXpPztfMz1sV0QlJFWpYaJ48OhKkM\nw3Ru9D9ykS0iP3c7mhqxK642xSkyyapU3Y70jl5GAWsJZDOKwcSYotiO0Zt4MrzO\noovVyze153vCU7zl5YXkew1oXxvwHKcQ2Dm/Rt+R4fSBNmiCUlufpIiwCV/9cjPk\na2u6VeVx2QKBgQCm4r3lVsNbbUkl90jRR4euEElewygTuEibxrvi/vi1yCfSS0U/\n74yv8nppaGihUi47nGFsjvW4oFzQ3QrYIPDErRyfG80eisa84FQN+ZBwdJ0n/8R7\nMhph7m4sLyEUylXKgBujNEuvdiZsQziCg4wOmMzq2CPXjY6SRr/HItNKaQKBgQCc\nQg8ulfYyEq1eUDNIKlmJ0B6GeXPCi2hS2gCZ5HqIcpYZQms/n+95rrSQaw5TZKiT\neXnylxAb4Z7PmNJQZKuNGQXxrUsSHLEAV5vNRbHAvLEu3qWWPnpoiWYjh0+lJfnC\nXpsGEVwzWygdwSy9Tofzp5UevjOoFnFrmmMaqjH3EQKBgQCUKnzxQj+3kpwOm3t+\nhjIWOZrO0f02mSWpp5yv52b6QXthmFboWDd5hewxlEy7ItIIGB5ySJTUhInNfTGZ\nQhCebFf8FGfGO2x0nk5dJ3SlNIYB7ksslVj+dFdFfQ0+o+TEt+g1U38Kldw+l2GE\nYQv5fkP8q0AEY4xzV9rjLTUgAg==\n-----END PRIVATE KEY-----\n',
            client_email: 'firebase-adminsdk-wf33y@id-document-ocr.iam.gserviceaccount.com',
            client_id: '104461201639915572422',
            auth_uri: 'https://accounts.google.com/o/oauth2/auth',
            token_uri: 'https://oauth2.googleapis.com/token',
            auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
            client_x509_cert_url:
                'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-wf33y%40id-document-ocr.iam.gserviceaccount.com',
        },
        firebaseProjectId: 'id-document-ocr',
        firebaseAppId: '1:446236997348:web:654e7fa79ea9e16a6bdef1',
        version: appVersion,
        elasticSearchKey: 'search-6v8xvcvzt8nn7ddrt6tkgojs',
        elasticEngineName: 'id-ocr-search',
        elasticEndpointBase: 'https://play-north-search.ent.europe-west4.gcp.elastic-cloud.com',
        idAnalyzerKey: 'GxhhaO6wBxXb0JycIeLSPsXxRpq7ofBo',
        sentryDsn: 'https://8189f8d8b5274630ba975608228c17c8@o207019.ingest.sentry.io/6094294',
        sentryEnvironment: 'aml-production',
        environment: 'production',
        resizeImages,
        blockedCountries,
        pusherAppId: '1693403',
        pusherAppKey: '73b5f07c360e54d117b8',
        pusherAppSecret: '017fdf21ce336cf5e2a8',
    },
}[appEnvironment];

export default config;
