// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import config from 'config';

const SENTRY_DSN = config.sentryDsn;
const SENTRY_ENVIRONMENT = config.sentryEnvironment;

Sentry.init({
    dsn: SENTRY_DSN || 'https://8189f8d8b5274630ba975608228c17c8@o207019.ingest.sentry.io/6094294',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    environment: SENTRY_ENVIRONMENT || 'staging',
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
