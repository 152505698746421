import * as React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import theme from '../src/theme';
import createEmotionCache from '../src/createEmotionCache';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/nextjs';
import { useRouter } from 'next/router';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

import Menu from 'components/Menu';
import SyncActiveOrganisation from 'components/SyncActiveOrganisation';
import Box from '@mui/material/Box';

export default function MyApp(props) {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
    const router = useRouter();

    return (
        <ClerkProvider {...pageProps}>
            <CacheProvider value={emotionCache}>
                <Head>
                    <title>Play North - OCR</title>
                    <meta name="viewport" content="initial-scale=1, width=device-width" />
                </Head>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <SignedIn>
                        <Menu content={null} />
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <Component {...pageProps} />
                        </Box>
                    </SignedIn>
                    <SignedOut>
                        {router.pathname.match('/sign-in') ? <Component {...pageProps} /> : <RedirectToSignIn />}
                    </SignedOut>
                </ThemeProvider>
            </CacheProvider>
        </ClerkProvider>
    );
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    emotionCache: PropTypes.object,
    pageProps: PropTypes.object.isRequired,
};
