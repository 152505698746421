import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            paper: '#F1F5FB',
            default: '#F1F5FB',
        },
        common: {
            dark: '#293039',
        },
        primary: {
            50: '#EBEAFC',
            100: '#D7D5F8',
            200: '#AFABF1',
            300: '#8880EB',
            400: '#6056E4',
            500: '#382CDD',
            600: '#2D23B1',
            700: '#221A85',
            800: '#161258',
            900: '#0B092C',
            main: '#382CDD',
            light: '#EBEAFC',
            dark: '#2D23B1',
            contrastText: '#FFFFFF',
        },
        success: {
            50: '#E8F8F3',
            100: '#D1F1E6',
            200: '#A2E4CE',
            300: '#74D6B5',
            400: '#45C99D',
            500: '#17BB84',
            600: '#12966A',
            700: '#0E704F',
            800: '#094B35',
            900: '#05251A',
            main: '#17BB84',
            light: '#E8F8F3',
            dark: '#12966A',
            contrastText: '#FFFFFF',
        },
        warning: {
            50: '#FEF2EA',
            100: '#FDE4D4',
            200: '#FBCAA9',
            300: '#FAAF7E',
            400: '#F89553',
            500: '#F67A28',
            600: '#C56220',
            700: '#944918',
            800: '#623110',
            900: '#311808',
            main: '#F67A28',
            light: '#FEF2EA',
            dark: '#C56220',
            contrastText: '#FFFFFF',
        },
        danger: {
            50: '#FDEEEC',
            100: '#FADDDA',
            200: '#F6BBB4',
            300: '#F1988F',
            400: '#ED7669',
            500: '#E85444',
            600: '#BA4336',
            700: '#8B3229',
            800: '#5D221B',
            900: '#2E110E',
            main: '#E85444',
            light: '#FDEEEC',
            dark: '#BA4336',
            contrastText: '#FFFFFF',
        },
        grey: {
            50: '#F1F5FB',
            100: '#E1E4E8',
            200: '#C2C9D2',
            300: '#A4AFBB',
            400: '#8594A5',
            500: '#67798E',
            600: '#526172',
            700: '#3E4955',
            800: '#293039',
            900: '#15181C',
            main: '#293039',
            light: '#F1F5FB',
            dark: '#15181C',
            contrastText: '#FFFFFF',
        },
        secondary: {
            50: '#F2EAFC',
            100: '#E6D4F8',
            200: '#CDA9F2',
            300: '#B37EEB',
            400: '#9A53E5',
            500: '#8128DE',
            600: '#6720B2',
            700: '#4D1885',
            800: '#341059',
            900: '#1A082C',
            main: '#F2EAFC',
            light: '#FFFFFF',
            dark: '#E6D4F8',
            contrastText: '#1A082C',
        },
        error: {
            50: '#FDEEEC',
            100: '#FADDDA',
            200: '#F6BBB4',
            300: '#F1988F',
            400: '#ED7669',
            500: '#E85444',
            600: '#BA4336',
            700: '#8B3229',
            800: '#5D221B',
            900: '#2E110E',
            main: '#E85444',
            light: '#FDEEEC',
            dark: '#BA4336',
            contrastText: '#FFFFFF',
        },
        info: {
            50: '#EAF1FE',
            100: '#D5E2FD',
            200: '#ABC6FB',
            300: '#81A9F9',
            400: '#578DF7',
            500: '#2D70F5',
            600: '#245AC4',
            700: '#1B4393',
            800: '#122D62',
            900: '#091631',
            main: '#2D70F5',
            light: '#EAF1FE',
            dark: '#245AC4',
            contrastText: '#FFFFFF',
        },
        white: {
            main: '#ffffff',
            dark: '#E1E4E8',
        },
    },
    shadows: ['none', '0px 4px 8px -4px rgba(21, 24, 28, 0.08)'],
    typography: {
        fontFamily: '"DM Sans", sans-serif',
        body1: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        button: {
            fontWeight: 500,
            fontFamily: '"DM Sans", sans-serif',
            textTransform: 'capitalize',
        },
        button2: {
            fontWeight: 500,
            fontFamily: '"DM Sans", sans-serif',
            textTransform: 'capitalize',
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            fontFamily: '"DM Sans", sans-serif',
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 500,
            fontFamily: '"DM Sans", sans-serif',
            lineHeight: 1.5,
        },
        h1: {
            fontSize: '3.5rem',
            fontWeight: 700,
            fontFamily: '"DM Sans", sans-serif',
            lineHeight: 1.5,
        },
        h2: {
            fontSize: '2.5rem',
            fontWeight: 700,
            fontFamily: '"DM Sans", sans-serif',
            lineHeight: 1.5,
        },
        h3: {
            fontSize: '2rem',
            fontWeight: 700,
            fontFamily: '"DM Sans", sans-serif',
            lineHeight: 1.5,
        },
        h4: {
            fontSize: '1.75rem',
            fontWeight: 700,
            fontFamily: '"DM Sans", sans-serif',
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '1.5rem',
            fontWeight: 700,
            fontFamily: '"DM Sans", sans-serif',
            lineHeight: 1.5,
        },
    },
});

export default theme;
